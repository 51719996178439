<template>
  <div class="av">
    <div class="container">
      <div class="av-details">
<!--        <div class="av-texts">-->
<!--          <p class="av__title">{{ data.title }}</p>-->
<!--          <div class="right-text-icon">-->
<!--            <router-link to="/">-->
<!--              <span class="right-text">{{ $t('Полный список') }}</span>-->
<!--              <i class="fas fa-bars"></i>-->
<!--            </router-link>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="av-counts">-->
<!--          <p class="av-count">Ливерпуль - Манчестер Юнайтед</p>-->
<!--        </div>-->
        <div class="av-section">
<!--          <p class="av-section-text">{{ $t('Реклама') }}</p>-->
<!--          <Adsense-->
<!--            class="av__adsense"-->
<!--            data-ad-client="ca-pub-5025320822350726"-->
<!--            data-ad-slot="1234567890"-->
<!--            data-ad-format="auto"-->
<!--            data-full-width-responsive="true"-->
<!--          />-->
          <div id="yandex_rtb_R-A-1459810-2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Ad',

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  mounted () {
    if (window.yaContextCb && window.Ya) {
      window.yaContextCb.push(() => {
        window.Ya.Context.AdvManager.render({
          renderTo: 'yandex_rtb_R-A-1459810-2',
          blockId: 'R-A-1459810-2',
          pageNumber: Math.round(Math.random() * 10),
          async: true
        })
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/elements/av';
</style>
